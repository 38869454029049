import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment';
import {RedfactHelper} from "../../../_utils/RedfactHelper";

// CTXT = context
const CTXT_KEY_DATA = 'account:data';
const CTXT_KEY_ACCOUNT_FETCH_PROMISE = 'account:data:fetchPromise';
const CTXT_KEY_EVENT_INITIAL_LOAD = 'event:account_data_initial_load';
const CTXT_KEY_EVENT_CHANGED = 'event:account_data_changed';
const SELECTOR_NAVIGATION_ACCOUNT = '#navigation-account';

export class AccountData {

  /**
   * @param {Context} context ist erforderlich um Account-Daten und Fetch-Promise dort abzulegen
   */
  constructor(context) {

    this.context = context;
    this.logInstance = new ConsoleDebug('debug-account', 'AccountData');
    this.logInstance.log('core/account/service/AccountData.js constructor');
    this.envInstance = new Environment();
    this.isInitialLoad = true;

    this.update = this.update.bind(this);
    this.getAccountData = this.getAccountData.bind(this);
    this.fetchAccountData = this.fetchAccountData.bind(this);

    // Wenn keine Account-Daten-Fetch-Promise existiert, dann initial Laden via Fetch-Promise
    if ( this.context.values.has(CTXT_KEY_ACCOUNT_FETCH_PROMISE) === false ) {
      this.fetchAccountData(CTXT_KEY_EVENT_INITIAL_LOAD);
    }
  }

  update () {
    this.logInstance.log('update() called');
    this.fetchAccountData(CTXT_KEY_EVENT_CHANGED);
  }

  /**
   * Account Daten im pacto context suchen und zurückgeben
   * Die Antwort kann zeitverzögert kommen, es wird auf den Fetch-Promise gewartet, bitte mit "await" aufrufen
   * @returns {string|boolean} false = failure | json daten vom server { "isLoggedIn": true, "isReloadRecommended": false, "userId": 201839, "firstname": "Tobias", "lastname": "Fenrich Spezialabo", "urlProfile": "https:\/\/www2authtest.mannheimer-morgen.de\/service\/mein_profil\/index.html", "urlLogout": "https:\/\/xmedias3.mannheimer-morgen.de\/REST\/community\/customlogout", "bineosPermission": "ssoSpecial"}
   */
  async getAccountData () {
    //this.logInstance.log('async getAccountData() called');

    let accountData = false;

    try {
      accountData = await this.#waitGetAccountData();
    } catch (error) {
      this.logInstance.log('1. Versuch account daten holen abgebrochen, Netzwerkfehler ?...' + error);
      this.logInstance.log('2. Versuch starten');
      let contextKeyEvent = this.isInitialLoad ? CTXT_KEY_EVENT_INITIAL_LOAD : CTXT_KEY_EVENT_CHANGED;
      await this.fetchAccountData(contextKeyEvent);
      accountData = await this.#waitGetAccountData();
    }

    //this.logInstance.log('async getAccountData() return', accountData);
    return accountData;
  }

  async #waitGetAccountData () {
    try {
      let accountData;
      if ( this.context.values.has(CTXT_KEY_ACCOUNT_FETCH_PROMISE) ) {
        await this.context.values.get(CTXT_KEY_ACCOUNT_FETCH_PROMISE);
        //this.logInstance.log('promise in pacto context \'' + CTXT_KEY_ACCOUNT_FETCH_PROMISE + '\' is resolved');
        if ( this.context.values.has(CTXT_KEY_DATA) ) {
          accountData = this.context.values.get(CTXT_KEY_DATA);
        }
        return accountData;
      }
    } catch(error) {
      this.logInstance.log('Fehler in der Funktion #waitGetAccountData ... ' + error);
      return false;
    }
  }

  /**
   * Account-Daten via Fetch-Promise vom redFACT-Server holen und im pacto context ablegen, falls empfohlen ein reload durchführen
   * @param {string} contextKeyEvent context key event e.g. "event:account_data_changed" or "event:account_data_initial_load"
   * @returns {*|Promise<unknown>} account data fetch promise
   */
  async fetchAccountData(contextKeyEvent) {
    this.logInstance.log('fetchAccountData() called');

    let endpoint;
    if ( this.envInstance.isLocalhost() ) {
      // If Fractal Web UI Server, then read endoint from attribute data-endpoint
      endpoint = document.querySelector(SELECTOR_NAVIGATION_ACCOUNT)?.dataset.endpoint;
    } else {
      // If redFACT web server, then use static path to redFACT REST service
      endpoint = (new RedfactHelper()).getRestEndpointAccount();
    }

    this.logInstance.log('fetchAccountData() - window.fetch(' + endpoint + ', { credentials: \'include\' })');
    const promiseAccountDataFetch = window.fetch(endpoint, {credentials: 'include'})
      .then((response) => response.json())
      .then((data) => {
        this.logInstance.log('fetchAccountData() response => ' + JSON.stringify(data));

        this.context.values.add(CTXT_KEY_DATA, data);
        this.logInstance.log('this.context.trigger(\'' + contextKeyEvent + '\')');
        this.context.trigger(contextKeyEvent, {foo: 'bar'});
        if ( contextKeyEvent === CTXT_KEY_EVENT_INITIAL_LOAD ) {
          this.isInitialLoad = false;
        }
      })
      .catch((error) => {
        this.logInstance.log('fetchAccountData() error => ' + error);
      });
    this.context.values.add(CTXT_KEY_ACCOUNT_FETCH_PROMISE, promiseAccountDataFetch);
    await promiseAccountDataFetch;
    return promiseAccountDataFetch;
  }


  /**
   * In Account Daten im pacto context nachsehen, ob der Nutzer angemeldet ist
   * @returns {boolean} true = Nutzer ist angemeldet
   */
  async isLoggedIn() {
    this.logInstance.log('async isLoggedIn() called');
    let isLoggedIn = false;
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.isLoggedIn === 'boolean' ) {
      isLoggedIn = accountData.isLoggedIn;
    }
    this.logInstance.log('async isLoggedIn() return', isLoggedIn);
    return isLoggedIn;
  }

  /**
   * In Account Daten im pacto context nachsehen, ob der Nutzer Zugriff auf Digital+ Inhalte (derzeit nur Artikel) hat
   * @returns {boolean} true = Zugriff gewährt
   */
  async hasAccessToDigitalPlus () {
    this.logInstance.log('async hasAccessToDigitalPlus() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.hasAccessToDigitalPlus === 'boolean' ) {
      return accountData.hasAccessToDigitalPlus;
    }
    return false;
  }

  /**
   * In Account Daten im pacto context nachsehen, ob der Nutzer Zugriff auf E-Paper hat
   * @returns {boolean} true = Zugriff gewährt
   */
  async hasAccessToEpaper () {
    this.logInstance.log('async hasAccessToEpaper() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.hasAccessToEpaper === 'boolean' ) {
      return accountData.hasAccessToEpaper;
    }
    return false;
  }

  /**
   * In Account Daten im pacto context nachsehen und Vorname zurückgeben
   * @returns {string} Vorname z.B. "Tobias"
   */
  async getFirstname () {
    this.logInstance.log('async getFirstname() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.firstname === 'string' ) {
      return accountData.firstname;
    }
    return '';
  }

  /**
   * In Account Daten im pacto context nachsehen und Nachname zurückgeben
   * @returns {string} Nachname z.B. "Fenrich"
   */
  async getLastname () {
    this.logInstance.log('async getLastname() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.lastname === 'string' ) {
      return accountData.lastname;
    }
    return '';
  }

  /**
   * In Account Daten im pacto context nachsehen und SSO-Nutzer-ID zurückgeben
   * @returns {number} SSO-Nutzer-ID z.B. "388309"
   */
  async getUserId () {
    this.logInstance.log('async getUserId() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.userId === 'number'
      && accountData.userId > 0 ) {
      return accountData.userId;
    }
    return 0;
  }

  /**
   * In Account Daten im pacto context nachsehen und Bineos-Permission zurückgeben
   * @returns {string} Bineos-Permission z.B. "sapPrint,sapEpaper"
   */
  async getBineosPermission () {
    this.logInstance.log('async getBineosPermission() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false && typeof accountData.bineosPermission === 'string' ) {
      return accountData.bineosPermission;
    }
    return '';
  }

  /**
   * In Account Daten im pacto context nachsehen und Logout-URL zurückgeben
   * @returns {string} Logout-URL z.B. "\/REST\/community\/customlogout"
   */
  async getUrlLogout () {
    this.logInstance.log('async getUrlLogout() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false && typeof accountData.urlLogout === 'string' ) {
      return accountData.urlLogout;
    }
    return '';
  }

  /**
   * In Account Daten im pacto context nachsehen und Self-Service-URL zurückgeben
   * @returns {string} Self-Service-URL z.B. "https://.../service\/mein_profil\/index.html"
   */
  async getUrlProfile () {
    this.logInstance.log('async getUrlProfile() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false && typeof accountData.urlProfile === 'string' ) {
      return accountData.urlProfile;
    }
    return '';
  }

  /**
   * Look in account data in the pacto context and return JWT for piano ID
   * @returns {string} JWT for piano ID
   */
  async getPianoJwt () {
    this.logInstance.log('async getPianoJwt() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false && typeof accountData.pianoJwt === 'string') {
      return accountData.pianoJwt;
    }
    return '';
  }

  /**
   * In Account Daten im pacto context nachsehen und Bineos-Permission zurückgeben
   * @returns {string} Bineos-Permission z.B. "sapPrint,sapEpaper"
   */
  async isReloadRecommended () {
    this.logInstance.log('async isReloadRecommended() called');
    const accountData = await this.getAccountData();
    if ( accountData !== false
      && typeof accountData.isReloadRecommended === 'boolean' ) {
      return accountData.isReloadRecommended;
    }
    return false;
  }
}

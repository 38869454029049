import { View } from 'pacto';
import { ConsoleDebug } from '../../../../_utils/ConsoleDebug';
import {Environment} from "../../../../_utils/Environment";
import {AccountData} from "../../../../core/account/service/AccountData";

const CTXT_KEY_EVENT_LOGIN_SUCCESS = 'event:account_login_successfully';
const CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED = 'event:account_data_changed';
const CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN = 'piano:paywall_offer_shown';
const LS_KEY_ACCOUNT_LAST_RELOAD_TIME = 'accountLastReloadTime';
const STOP_RELOAD_TTL = 3600; // 1 hour

export class Paywall extends View {

  constructor(options) {
    super(options);

    this.env = new Environment();
    this.logInstance = new ConsoleDebug('debug-account', 'Paywall');
    this.logInstance.log('common/pianopay/paywall/views/Paywall.js constructor');
    this.accountData = new AccountData(this.context);
    this.preventReload = false;
    this.reloadWithLoopProtection = this.reloadWithLoopProtection.bind(this);
  }

  render() {

    // handle the explicit 'loginRequired' Piano callback and prevent reload in this case
    window.tp = window.tp || [];
    window.tp.push(["addHandler", "loginRequired", (pianoCheckoutParams) => {
      this.logInstance.log('Piano callback \'loginRequired\' triggered, set preventReload=true');
      this.preventReload = true;
    }]);

    // Listen for event "Login successfully" and reload for the redFACT Payment Cookie
    this.context.on(CTXT_KEY_EVENT_LOGIN_SUCCESS, (event) => {
      if ( !this.preventReload ) {
        this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_LOGIN_SUCCESS + '\' reagiert, do reload', event);
        window.location.reload();
      }
    });

    // Die View soll selbstständig auf Veränderungen der Account-Daten reagieren
    this.context.on(CTXT_KEY_EVENT_ACCOUNT_DATA_CHANGED, async (event) => {
      this.logInstance.log('Daten wurden geändert, check preventReload and isReloadRecommended', event);
      if ( this.preventReload ) {
        this.logInstance.log('prevent reload in this case, because loginRequired piano callback', event);
      } else if ( await this.accountData.isReloadRecommended() ) {
        this.logInstance.log('redfact payment cookie is expired, reload is recommended to grant paywall access');
        this.reloadWithLoopProtection();
      }
    });

    if ( !this.context.values.has(CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN) ) {
      this.logInstance.log('context.values.has(' + CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN + ') is false, '
        + '\'showOffer\' for paywall has not been shown yet, then to explicit window.tp.experience.execute()');
      window.tp.experience?.execute();
    }

    return this;
	}


  /**
   * Reload only if no reload by the same function has taken in the last hour. Necessary to prevent redirect loops.
   * Reload only if the host subdomain match to a redfact vhost.
   */
  reloadWithLoopProtection() {
    this.logInstance.log('reloadWithLoopProtection() called');

    // SP-1330 don't reload if host subdomain doesn't match to a redfact vhost
    if (location.host.match(/^(www|red|prelive|red-prelive|integration|test\d+|xmedias\d+|dev\d+)\./) === null) {
      this.logInstance.log('reloadWithLoopProtection() - Prevent reloading because host subdomain doesn\'t match to a redfact vhost');
      return;
    }

    let lastReloadTime = localStorage.getItem(LS_KEY_ACCOUNT_LAST_RELOAD_TIME);
    if (lastReloadTime !== null) {
      let currentTime = Math.floor(Date.now() / 1000);
      let diffInSec = currentTime - lastReloadTime;

      if (diffInSec < STOP_RELOAD_TTL) {
        this.logInstance.log('reloadWithLoopProtection() - Prevent reloading since it was last ' + diffInSec + ' seconds ago');
        return;
      }
    }

    localStorage.setItem(LS_KEY_ACCOUNT_LAST_RELOAD_TIME, Math.floor(Date.now() / 1000));
    this.logInstance.log('reloadWithLoopProtection() - Reload now and save time in localStorage \'' + LS_KEY_ACCOUNT_LAST_RELOAD_TIME + '\' to prevent redirect loops');
    window.location.reload();
  }
}
